import React from 'react'
import { useTitle } from '../utils/useTitle'

function Board() {
  useTitle('File Sharing etc.')
  return (
    <>
      {/* <h2>SFU version</h2> */}
      <div className="chat">
        <iframe
          title="board"
          src="https://memo.sv.showofhands.xyz/"
          allow="microphone *;"
          width="100%"
          height="90%"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  )
}

export default Board
