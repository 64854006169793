import { useLocation, Link as RouterLink } from 'react-router-dom'
// import { Link } from 'react-router-dom';
import { MenuItem, useMediaQuery, Divider, Grid } from '@material-ui/core'
import { useMenuStyles } from '../styles/muiStyles'
import { useTheme } from '@material-ui/core/styles'
import PublicIcon from '@material-ui/icons/Public'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import PeopleIcon from '@material-ui/icons/People'
import MicIcon from '@material-ui/icons/Mic'
import PostAddIcon from '@material-ui/icons/PostAdd';

const DesktopNavMenu = () => {
  const { pathname } = useLocation()
  const classes = useMenuStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  if (isMobile) return null

  return (
    <Grid item>
      <div className={classes.rootPanel}>
        <div className={classes.list}>
          {/* <MenuItem
            selected={pathname.startsWith('/')}
            component={RouterLink}
            to="/calendar"
          >
            Calendar
          </MenuItem>       */}
          {/* <MenuItem
            component="a"
            href="https://audio.groundwire.xyz"
            target="_blank"
        >Editor</MenuItem> */}
          {/* <MenuItem
            component="a"
            href="https://tuner.groundwire.xyz"
            // target="_blank"
        >Tuner</MenuItem> */}
          {/* <MenuItem
            component="a"
            href="https://fs.groundwire.xyz"
            // target="_blank"
        >Note Board</MenuItem> */}
          {/* <MenuItem
            component="a"
            href="https://lobby.groundwire.xyz"
            target="_blank"
        >Lobby</MenuItem> */}
          {/* <MenuItem
            selected={pathname.startsWith('/editor')}
            component={RouterLink}
            to="/editor"
          >
            Audio Editor
          </MenuItem> */}
          <MenuItem
            selected={
              pathname === '/' ||
              (!pathname.startsWith('/tag') && !pathname.startsWith('/user'))
            }
            component={RouterLink}
            to="/"
          >
            <PostAddIcon className={classes.menuIcon} />
            Posts
          </MenuItem>

          {/* <MenuItem
            selected={pathname.startsWith('/call')}
            component={RouterLink}
            to="/call"
          >
            Audio Call
          </MenuItem> */}
          {/* <MenuItem
            selected={pathname.startsWith('/lobby')}
            component={RouterLink}
            to="/lobby"
          >
            Doorbell
          </MenuItem> */}
          {/* <MenuItem
            selected={pathname.startsWith('/board')}
            component={RouterLink}
            to="/board"
          >
            Note Board
          </MenuItem> */}
          <MenuItem
            selected={pathname.startsWith('/user')}
            component={RouterLink}
            to="/users"
          >
            <PeopleIcon className={classes.menuIcon} />
            Subscribers
          </MenuItem>
          <MenuItem
            selected={pathname.startsWith('/tag')}
            component={RouterLink}
            to="/tags"
          >
            <LocalOfferIcon className={classes.menuIcon} />
            Tags
          </MenuItem>
          {/* <MenuItem
            selected={pathname.startsWith('/tuner')}
            component={RouterLink}
            to="/tuner"
          >
            Tuner
          </MenuItem> */}
          {/* <MenuItem
            selected={pathname.startsWith('/')}
            component={RouterLink}
            to="/link"
          >
            Voice Chat
          </MenuItem> */}
        </div>
        <Divider orientation="vertical" flexItem />
      </div>
    </Grid>
  )
}

export default DesktopNavMenu
