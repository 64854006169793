import React from 'react';
import Cal from "@calcom/embed-react";


import "@calcom/embed-react";

// const MyComponent = ()=> {
//   return <button data-cal-link="jane" data-cal-config='A valid config JSON'></button>
// }

function Calendar() {
  return (
    <div style={{ margin: '0, auto', padding: '0rem 0rem 0rem 0rem',  justifyContent: 'center', background: 'transparent', width: '100vw' }}>
    <div className="cal">
    <Cal
    calLink="ohelo"
    config={{
      name: "Ohelo Studio",
      email: "ohelostudio@gmail.com",
      notes: "Test Meeting",
      guests: ["janedoe@gmail.com"],
      theme: "light",
    }}
  />
      </div>
    </div>
  );
}
export default Calendar