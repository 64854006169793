import React from 'react'
import { useTitle } from '../utils/useTitle'



function Editor() {
  useTitle("Audio Editor")
  return (
    <>
    
        {/* <h2>SFU version</h2> */}
        <div className="daw">
          <iframe
            title="editor"
            src="https://rec.sv.dbel.xyz/"
            allow="microphone *;"
            width="100%"
            height="90%"
            frameBorder="0"
          ></iframe>
        </div>
     
    </>   
  )
}

export default Editor