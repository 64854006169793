import { useTitle } from '../utils/useTitle'

function Lobby() {
  useTitle('Doorbell')
  return (
    <>
      <div className="chat">
        <iframe
          title="lobby"
          src="https://lobby.sv.showofhands.xyz/"
          allow="microphone *;"
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  )
}

export default Lobby
