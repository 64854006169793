import { useState } from 'react'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import { ReactComponent as UpvoteIcon } from '../svg/upvote.svg'
import { ReactComponent as DownvoteIcon } from '../svg/downvote.svg'

import { DialogTitle } from './CustomDialogTitle'
import {
  Dialog,
  DialogContent,
  Button,
  MenuItem,
  useMediaQuery,
  Link,
  SvgIcon,
  IconButton,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useDialogStyles } from '../styles/muiStyles'
// import PersonAddIcon from '@material-ui/icons/PersonAdd'
// import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import styled from 'styled-components'

const AuthFormModal = ({ closeMenu, buttonType }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [authType, setAuthType] = useState('login')
  const classes = useDialogStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const handleLoginModal = () => {
    setAuthType('login')
    setModalOpen(true)
    if (buttonType === 'mobile') {
      closeMenu()
    }
  }

  const handleSignupModal = () => {
    setAuthType('signup')
    setModalOpen(true)
    if (buttonType === 'mobile') {
      closeMenu()
    }
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const StyledButton = styled(Button)`
    background-color: #64a4d4;
    /* color: #fff; */
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
    &:hover {
      background-color: #94d1ff;
    }
  `

  const triggerButton = () => {
    if (buttonType === 'ask') {
      return (
        <StyledButton
          variant="contained"
          size={isMobile ? 'small' : 'medium'}
          style={{ minWidth: '9em', background: '#967bb6', color: '#fff' }}
          onClick={handleLoginModal}
        >
          Post
        </StyledButton>
      )
    } else if (buttonType === 'link') {
      return (
        <Link onClick={handleLoginModal} style={{ cursor: 'pointer' }}>
          post your own post.
        </Link>
      )
    } else if (buttonType === 'upvote') {
      return (
        <IconButton onClick={handleLoginModal}>
          <SvgIcon className={classes.upDownIcon}>
            <UpvoteIcon />
          </SvgIcon>
        </IconButton>
      )
    } else if (buttonType === 'downvote') {
      return (
        <IconButton onClick={handleLoginModal}>
          <SvgIcon className={classes.upDownIcon}>
            <DownvoteIcon />
          </SvgIcon>
        </IconButton>
      )
    } else if (buttonType === 'mobile') {
      return (
        <div>
          <MenuItem onClick={handleLoginModal}>
            {/* <ExitToAppIcon className={classes.menuIcon} /> */}
            Log In
          </MenuItem>
          <MenuItem onClick={handleSignupModal}>
            {/* <PersonAddIcon className={classes.menuIcon} /> */}
            Subscribe
          </MenuItem>
          {/* <MenuItem
            component="a"
            href="https://lobby.groundwire.xyz"
            target="_blank"
        >Lobby</MenuItem> */}
        </div>
      )
    } else {
      return (
        <div>
          <Button
            color="inherit"
            variant="contained"
            size="small"
            style={{ marginRight: 7 }}
            onClick={handleLoginModal}
          >
            Log In
          </Button>
          <Button
            color="inherit"
            variant="contained"
            size="small"
            onClick={handleSignupModal}
          >
            Subscribe
          </Button>
          {/* <Button
            color="inherit"
            // variant="contained"
            size="small"
            component="a"
            href="https://lobby.groundwire.xyz"
            target="_blank"
          >
            Lobby
          </Button> */}
        </div>
      )
    }
  }

  return (
    <div style={{ display: 'inline' }}>
      {triggerButton()}
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        maxWidth="sm"
        classes={{ paper: classes.dialogWrapper }}
      >
        <DialogTitle onClose={handleModalClose}></DialogTitle>
        <DialogContent>
          {authType === 'login' ? (
            <LoginForm
              setAuthType={setAuthType}
              closeModal={handleModalClose}
            />
          ) : (
            <RegisterForm
              setAuthType={setAuthType}
              closeModal={handleModalClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AuthFormModal
