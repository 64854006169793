import NavBar from './components/NavBar'
import ToastNotification from './components/ToastNotification'
import Routes from './pages/Routes'
// import { useStateContext } from './context/state';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
// import useLocalStorage from 'use-local-storage'
// import { useBodyStyles } from './styles/muiStyles';
// import { Paper } from '@material-ui/core/';
// import { ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Zen Kaku Gothic New', 'sans-serif'].join(','),
  },
})

const App = () => {
  // const { darkMode } = useStateContext();
  // const classes = useBodyStyles();

  return (
    <ThemeProvider theme={theme}>
      <>
        <ToastNotification />
        <NavBar />
        <Routes />
      </>
    </ThemeProvider>
  )
}

export default App
