import { ReactComponent as Error404 } from '../svg/404-error.svg'

import { Typography, SvgIcon } from '@material-ui/core'
import { useNotFoundPageStyles } from '../styles/muiStyles'

const NotFoundPage = () => {
  const classes = useNotFoundPageStyles()

  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        <SvgIcon color="inherit" className={classes.icon}>
          <Error404 />
        </SvgIcon>
        <Typography color="inherit" variant="h5">
          Page Not Found
        </Typography>
        <Typography color="inherit" variant="body1">
          The page you requested does not exist.
        </Typography>
      </div>
    </div>
  )
}

export default NotFoundPage
