import { useTitle } from '../utils/useTitle'

function Call() {
  useTitle('pono.pages.dev/call')
  return (
    <>
      <div className="chat">
        <iframe
          title="lobby"
          src="https://miro.sv.dbel.xyz/"
          allow="camera; microphone; fullscreen; display-capture; autoplay"
          width="100%"
          height="90%"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  )
}

export default Call
