import React from 'react'
import { useTitle } from '../utils/useTitle'



function Tuner() {
  useTitle("Tuner")
  return (
    <>
    
        {/* <h2>SFU version</h2> */}
        <div className="chat">
          <iframe
            title="tuner"
            src="https://tuner.sv.showofhands.xyz/"
            allow="microphone *;"
            width="100%"
            height="100%"
            frameBorder="0"
          ></iframe>
        </div>
     
    </>   
  )
}

export default Tuner