import { useState } from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'

import {
  IconButton,
  Menu,
  MenuItem,
  // Divider,
  // Typography,
  // Link,
} from '@material-ui/core'
import { useMenuStyles } from '../styles/muiStyles'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import PublicIcon from '@material-ui/icons/Public'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import PeopleIcon from '@material-ui/icons/People'
// import FavoriteIcon from '@material-ui/icons/Favorite';
import MicIcon from '@material-ui/icons/Mic'
import PostAddIcon from '@material-ui/icons/PostAdd';

const MobileNavMenu = () => {
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useMenuStyles()

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton color="inherit" onClick={handleOpenMenu}>
        {!anchorEl ? <MenuIcon /> : <CloseIcon className={classes.closeIcon} />}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        marginThreshold={0}
        elevation={1}
      >
        {/* <MenuItem
          selected={pathname.startsWith('/calendar')}
          component={RouterLink}
          to="/calendar"
          onClick={handleCloseMenu}
        >
          Calendar
        </MenuItem> */}
        {/* <MenuItem
          component="a"
          href="https://audio.groundwire.xyz"
          target="_blank"
        >
          Editor
        </MenuItem> */}
        {/* <MenuItem
          component="a"
          href="https://tuner.groundwire.xyz"
          // target="_blank"
        >
          Tuner
        </MenuItem> */}
        {/* <MenuItem
          component="a"
          href="https://fs.groundwire.xyz"
          // target="_blank"
        >
          Note Board
        </MenuItem> */}
        {/* <MenuItem
          selected={pathname.startsWith('/editor')}
          component={RouterLink}
          to="/editor"
          onClick={handleCloseMenu}
        >
          Audio Editor
        </MenuItem> */}
        {/* <MenuItem
          selected={pathname.startsWith('/call')}
          component={RouterLink}
          to="/call"
          onClick={handleCloseMenu}
        >
          Call
        </MenuItem> */}
        {/* <MenuItem
          selected={pathname.startsWith('/lobby')}
          component={RouterLink}
          to="/lobby"
          onClick={handleCloseMenu}
        >
          Doorbell
        </MenuItem> */}
        {/* <MenuItem
          selected={pathname.startsWith('/board')}
          component={RouterLink}
          to="/board"
          onClick={handleCloseMenu}
        >
          Note Board
        </MenuItem> */}
        <MenuItem
          selected={
            pathname === '/' ||
            (!pathname.startsWith('/tag') && !pathname.startsWith('/user'))
          }
          // dense
          component={RouterLink}
          to="/"
          onClick={handleCloseMenu}
        >
          <PostAddIcon className={classes.menuIcon} />
          Posts
        </MenuItem>

        <MenuItem
          selected={pathname.startsWith('/user')}
          // dense
          component={RouterLink}
          to="/users"
          onClick={handleCloseMenu}
        >
          <PeopleIcon className={classes.menuIcon} />
          Subscribers
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith('/tag')}
          // dense
          component={RouterLink}
          to="/tags"
          onClick={handleCloseMenu}
        >
          <LocalOfferIcon className={classes.menuIcon} />
          Tags
        </MenuItem>
        
        {/* <MenuItem
          selected={pathname.startsWith('/tuner')}
          component={RouterLink}
          to="/tuner"
          onClick={handleCloseMenu}
        >
          Tuner
        </MenuItem> */}
        {/* <MenuItem
          selected={pathname.startsWith('/link')}
          component={RouterLink}
          to="/link"
          onClick={handleCloseMenu}
        >
          Voice Chat
        </MenuItem> */}
        
      </Menu>
    </div>
  )
}

export default MobileNavMenu
